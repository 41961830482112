import React from 'react'; 
import Index5Page from '../pages/Index5Page'; 
import AboutPage from '../pages/AboutPage'; 
import CreativeAgencyPage from '../pages/CreativeAgencyPage'; 
import PortfolioPage from '../pages/PricingPage'; 
import ContactUs from '../pages/ContactPage'; 
import PortfolioDetailsPage from '../pages/PortfolioDetailsPage';

export const RouteList = [
    {
        path: '',
        element : <Index5Page/>
    },
    {
        path: 'about-us',
        element : <AboutPage/>
    },
    {
        path: 'services',
        element : <CreativeAgencyPage/>
    },
    {
        path: 'portfolio',
        element : <PortfolioPage/>
    },
    {
        path: 'portfolio-detail',
        element : <PortfolioDetailsPage/>
    },
    {
        path: 'contact-us',
        element : <ContactUs/>
    },
    {
        path: 'lets-talk',
        element : <ContactUs/>
    }
];
