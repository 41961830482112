 import {React,useState , useEffect} from 'react';
import {Link} from 'react-router-dom'; 
import { Fragment } from "react";
import Slider from "react-slick";
import {
  clientCarousel,
  pricingcenterslide,
  testimonialslidervertical,
} from "../sliderProps";
import Counter from "../components/Counter"; // Assuming Counter is implemented and imported

import TextTransition, { presets } from 'react-text-transition';
const TEXTS = ['Web Development', 'Web Design', 'ReactJs', 'CMS Dev','Framework Dev','SEO' , 'Mobile Development', 'Ecommerce'];



// // const Counter = dynamic(() => import("../components/Counter"), {
// //   ssr: false,
// // });

// const Counter = lazy(() => import("../components/Counter"),{
//   ssr: false,
// });


const Index5Page = () => {
 

    const [index, setIndex] = useState(0);
  
    useEffect(() => {
      const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        2000,  
      );
      return () => clearTimeout(intervalId);
    }, []);

    

  return (
    <Fragment>
      <section className="hero-area-four">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
              <div className="hero-content wow fadeInUp mt-5" data-wow-delay="0.4s">
                <a
                  href="https://www.youtube.com/watch?v=XSGBVzeBUbk"
                  className="video-btn d-none"
                  data-lity=""
                >
                  <i className="fas fa-play" />
                </a>
                <h1 className="hero-title">WE PROVIDE OUSTANDING</h1>
                <h1 className="hero-slider mb-4"><TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition></h1>

                <p>With TrueWebMakers responsive landing page template, you can promote your all web design & development services.</p>
                <Link to='/contact-us'  className="template-btn bordered-btn bordered-fourth" >Start a Project <i className="far fa-long-arrow-right" /></Link>
               
              </div>
            </div>
          </div>
        </div>
        <div className="hero-img d-none d-lg-block">
          <img src="/assets/img/hero/hero-design-studio.jpg" alt="HeroImage" />
        </div>
      </section>
      {/*====== End Hero Area ======*/}
      {/*====== Feature Section Start ======*/}
      <section className="feature-section design-studio-feature">
        <div className="container wow fadeInUp" data-wow-delay="0.4s">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="iconic-box-two white-version mb-30">
                <div className="icon">
                  <img src="/assets/img/icon/megaphone.png" alt="Megaphone" />
                </div>
                <div className="content">
                  <h4 className="title">
                    <a href="">Web Development</a>
                  </h4>
                  <p>
                  The design exists to present this information in an organized way and to add functionality.
                  </p>
                  <a href="#" className="box-link">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="iconic-box-two white-version mb-30">
                <div className="icon">
                  <img src="/assets/img/icon/target.png" alt="Target" />
                </div>
                <div className="content">
                  <h4 className="title">
                    <a href="">PHP Development</a>
                  </h4>
                  <p>
                  PHP is considered technology for its ease of use, flexibility support for a wide range of frameworks
                  </p>
                  <a href="#" className="box-link">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="iconic-box-two white-version mb-30">
                <div className="icon">
                  <img src="/assets/img/icon/calendar.png" alt="Megaphone" />
                </div>
                <div className="content">
                  <h4 className="title">
                    <a href="">Web Design</a>
                  </h4>
                  <p>
                     A suitable design is not only good for you, but it can also save your visitors a lot of money.
                  </p>
                  <a href="#" className="box-link">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="iconic-box-two white-version mb-30">
                <div className="icon">
                  <img src="/assets/img/icon/sms.png" alt="Megaphone" />
                </div>
                <div className="content">
                  <h4 className="title">
                    <a href="">Mobile App Development</a>
                  </h4>
                  <p> They play an important role in remotely accessing the integrated domain in the workplace.
                  </p>
                  <a href="#" className="box-link">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="iconic-box-two white-version mb-30">
                <div className="icon">
                  <img
                    src="/assets/img/icon/cloud-computing.png"
                    alt="Megaphone"
                  />
                </div>
                <div className="content">
                  <h4 className="title">
                    <a href="">SEO Optimization</a>
                  </h4>
                  <p>
                  SEO benefits small businesses in several ways, including the ability to build functional and easy-to-use websites.
                  </p>
                  <a href="#" className="box-link">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="iconic-box-two white-version mb-30">
                <div className="icon">
                  <img
                    src="/assets/img/icon/business-target.png"
                    alt="Megaphone"
                  />
                </div>
                <div className="content">
                  <h4 className="title">
                    <a href="">Ecommerce Stores</a>
                  </h4>
                  <p>
                  The ease of purchase, the secure transaction process, and the wide platform to conveniently select products or services
                  </p>
                  <a href="#" className="box-link">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== Feature Section End ======*/}
      {/*====== About Section Start ======*/}
      <section className="about-section section-gap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-7">
              <div
                className="about-img content-mb-md-5 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <img
                  src="/assets/img/illustrations/about-illustration-2.png"
                  alt="Illustrations"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-9">
              <div className="about-text content-x-spacing">
                <div className="section-heading mb-20">
                  <span className="tagline-two">About Company</span>
                  <h2 className="title">Who We Are</h2>
                </div>
                <p className="mb-1 text-justify">
                TrueWebMakers is a leading web design company providing a range of web design and development services
                with a passion for creating cutting-edge websites for your business. We provide exceptional web design 
                services with a passion for improving brand visibility through custom website development where you can 
                host the website of your choice.
                </p>
                <div className="row">
                  <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="check-list-box mt-20">
                      <i className="far fa-check-circle" />
                      Web Development
                    </div>
                  </div>
                  <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.4s">
                    <div className="check-list-box mt-20">
                      <i className="far fa-check-circle" />
                      PHP Development
                    </div>
                  </div>
                  <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="check-list-box mt-20">
                      <i className="far fa-check-circle" />
                      Web Design
                    </div>
                  </div>
                  <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.6s">
                    <div className="check-list-box mt-20">
                      <i className="far fa-check-circle" />
                      MobileApp Development
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== About Section End ======*/}
      {/*====== Brand Section Start ======*/}
      <section className="clients-section bg-color-secondary">
        <div className="container">
          <div className="client-carousel-heading">
            <h6>We Have 50+ Global Clients</h6>
            <div className="client-carousel-arrows" />
          </div>
          <Slider {...clientCarousel} className="client-carousel-active">
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/01-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/02-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/03-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/04-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/05-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/06-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/01-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/02-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/03-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/04-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/05-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
            <div className="col">
              <a href="#">
                <img
                  src="/assets/img/brand-logo/06-white.png"
                  alt="Brand logo"
                />
              </a>
            </div>
          </Slider>
        </div>
      </section>
      {/*====== Brand Section End ======*/}
      {/*====== Portfolio Section Start ======*/}
      <section className="portfolio-section section-gap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-11">
              <div className="section-heading text-center mb-30">
                <span className="tagline-two both-border">Latest Project</span>
                <h2 className="title">
                  We Have Lot’s Of Project That We Recently Completed
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="portfolio-items-three mt-30">
                <div className="portfolio-thumb">
                  <img src="/assets/img/portfolio/26.jpg" alt="Image" />
                </div>
                <div className="portfolio-content">
                  <h4 className="title">
                    <a href="#">Book Cover Design</a>
                  </h4>
                  <p className="tags">
                    <a href="#">Design,</a>
                    <a href="#">Branding</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="portfolio-items-three mt-30">
                <div className="portfolio-thumb">
                  <img src="/assets/img/portfolio/27.jpg" alt="Image" />
                </div>
                <div className="portfolio-content">
                  <h4 className="title">
                    <a href="#">Illustrator Design</a>
                  </h4>
                  <p className="tags">
                    <a href="#">Design,</a>
                    <a href="#">Branding</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="portfolio-items-three mt-30">
                <div className="portfolio-thumb">
                  <img src="/assets/img/portfolio/28.jpg" alt="Image" />
                </div>
                <div className="portfolio-content">
                  <h4 className="title">
                    <a href="#">Cup Cover Design</a>
                  </h4>
                  <p className="tags">
                    <a href="#">Design,</a>
                    <a href="#">Branding</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="portfolio-items-three mt-30">
                <div className="portfolio-thumb">
                  <img src="/assets/img/portfolio/30.jpg" alt="Image" />
                </div>
                <div className="portfolio-content">
                  <h4 className="title">
                    <a href="#">Mockup Design</a>
                  </h4>
                  <p className="tags">
                    <a href="#">Design,</a>
                    <a href="#">Branding</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="portfolio-items-three mt-30">
                <div className="portfolio-thumb">
                  <img src="/assets/img/portfolio/29.jpg" alt="Image" />
                </div>
                <div className="portfolio-content">
                  <h4 className="title">
                    <a href="#">App Design</a>
                  </h4>
                  <p className="tags">
                    <a href="#">Design,</a>
                    <a href="#">Branding</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="text-center">
                <a href="#" className="template-btn bg-fourth mt-50">
                  View More Project <i className="far fa-long-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== Portfolio Section End ======*/}
      {/*====== Statices Section Start ======*/}
      <section className="statices-section">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-7 col-lg-10 col-md-10">
              <div className="statices-img text-xl-left text-center">
                <img src="/assets/img/statices-img.jpg" alt="Image" />
              </div>
            </div>
            <div className="col-xl-5 col-lg-8 col-md-8">
              <div className="statices-content">
                <div className="section-heading mb-50">
                  <span className="tagline-two">Company Statices</span>
                  <h2 className="title">Someting Know About Our Achievement</h2>
                </div>
                <div className="row justify-content-center">
                  <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="counter-item counter-box-two">
                      <div className="icon">
                        <img src="/assets/img/icon/target.png" alt="Target" />
                      </div>
                      <div className="counter-wrap">
                        <Counter end={300} />
                        <span className="suffix">+</span>
                      </div>
                      <p className="title">Project Done</p>
                    </div>
                    <div className="counter-item counter-box-two mt-30">
                      <div className="icon">
                        <img src="/assets/img/icon/trophy.png" alt="Target" />
                      </div>
                      <div className="counter-wrap">
                        <Counter end={10} />
                        <span className="suffix">+</span>
                      </div>
                      <p className="title">Awards Winning</p>
                    </div>
                  </div>
                  <div className="col-sm-6 wow fadeInUp" data-wow-delay="0.4s">
                    <div className="counter-item counter-box-two mt-30">
                      <div className="icon">
                        <img src="/assets/img/icon/reviews.png" alt="Target" />
                      </div>
                      <div className="counter-wrap">
                        <Counter end={50} />
                        <span className="suffix">+</span>
                      </div>
                      <p className="title">Global Parters</p>
                    </div>
                    <div className="counter-item counter-box-two mt-30">
                      <div className="icon">
                        <img src="/assets/img/icon/object.png" alt="Target" />
                      </div>
                      <div className="counter-wrap">
                        <Counter end={15} />
                        <span className="suffix">+</span>
                      </div>
                      <p className="title">Creative Teams</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== Statices Section End ======*/}
      {/*====== Testimonial Section Start ======*/}
      <section className="testimonial-section section-gap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.4s">
              <div className="section-heading text-center mb-50">
                <span className="tagline-two">Our Testimonials</span>
                <h2 className="title">What Our Clients Say About Solutions</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-8">
              <div className="testimonial-img text-lg-left text-center content-mb-md-50">
                <img src="/assets/img/testimonial-img.png" alt="Image" />
              </div>
            </div>
            <div className="col-lg-7 col-md-11">
              <Slider
                    {...testimonialslidervertical}
                    className="testimonial-slider-vertical"
                  >
                <div className="testimonial-item-two">
                  <div className="content">
                    <p>
                       I am really impressed by the quality of services I received from TrueWebMakers. You were right on schedule, charged reasonable prices, were professional and courteous in dealings, and delivered items well before time. I have got a good site for my products. My revenue has increased because of TrueWebMakers and I will definitely use their services again.
                    </p>
                  </div>
                  <div className="author">
                    <div className="author-photo">
                      <img
                        src="/assets/img/author/02.png"
                        alt="William P. Harris"
                      />
                    </div>
                    <div className="author-info">
                      <h4 className="name">Amir Shaikh</h4>
                      <span className="title">CEO of WeQuote</span>
                    </div>
                  </div>
                </div> 

                <div className="testimonial-item-two">
                  <div className="content">
                    <p>
                    Amazing team to work with
                    </p>
                    </div>
                  <div className="author">
                    <div className="author-photo">
                      <img
                        src="/assets/img/author/02.png"
                        alt="William P. Harris"
                      />
                    </div>
                    <div className="author-info">
                      <h4 className="name">Arthua Saikrishna</h4>
                      <span className="title">Founder Of Hyperiontech</span>
                    </div>
                  </div>
                </div>  
                <div className="testimonial-item-two">
                  <div className="content">
                    <p>
                    Mr Paras at this TrueWebMakers is a developer with a passion for satisfying the client at the utmost level. He brought my application to a complete Professionally working application with all advanced features. I feel very comfortable communicating with him. I am very confident that my application would be 100% safe and secure in his hands. His timely response made my application reach my targets and goals. I am happy to have him as my site’s developer. i thank him and promise him that my future projects will also be kept in his safe hands.                    </p>
                  </div>
                  <div className="author">
                    <div className="author-photo">
                      <img
                        src="/assets/img/author/02.png"
                        alt="William P. Harris"
                      />
                    </div>
                    <div className="author-info">
                      <h4 className="name">Vijay Kumar</h4>
                      <span className="title">Vision Foundation</span>
                    </div>
                  </div>
                </div>  
                <div className="testimonial-item-two">
                  <div className="content">
                    <p>
                    I really appreciate the professional and timely project delivery by TrueWebMakers. Absolute sharp and skillful company in software-related projects. I’m definitely going to do more projects with TrueWebMakers in the future. Thank you for your services guys!                    </p>
                  </div>
                  <div className="author">
                    <div className="author-photo">
                      <img
                        src="/assets/img/author/02.png"
                        alt="William P. Harris"
                      />
                    </div>
                    <div className="author-info">
                      <h4 className="name">Danial Shaikh</h4>
                      <span className="title">MD of WeQuote</span>
                    </div>
                  </div>
                </div> 
               
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/*====== Testimonial Section End ======*/}
      {/*====== Pricing Section Start ======*/}
      <section className="pricing-section-start bg-color-fourth section-gap section-polygon-bg">
        <div className="container">
          <div className="row align-items-center justify-content-lg-between justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-10">
              <div className="section-heading heading-white mb-20">
                <span className="tagline-two">Pricing Plan</span>
                <h2 className="title">
                  Best Pricing For Create Awesome Web Design
                </h2>
              </div>
              <p className="text-white">
                Neque porro quisquam estui dolorem ipsum quia consectetur
                adipisci velitsed quia non numquames labore et dolore magnam
                aliquam
              </p>
              <Link href="/pricing">
                <a className="template-btn bordered-btn bordered-white mt-30">
                  View Pricing <i className="far fa-long-arrow-right" />
                </a>
              </Link>
            </div>
            <div className="col-lg-7 col-md-10 col-sm-10">
              <Slider
                {...pricingcenterslide}
                className="pricing-center-slide content-mt-md-50"
              >
                <div className="pricing-slide-item">
                  <div className="pricing-table pricing-table-three featured-plan">
                    <h4 className="plan-name">Standard Plan</h4>
                    <p>
                      Consectetu adipiscing elit sedie mod tempor incidie labore
                    </p>
                    <div className="plan-price">
                      <span className="currency">$</span>
                      <span className="price">49.86</span>
                    </div>
                    <a href="#" className="template-btn bordered-btn">
                      Choose Package <i className="far fa-long-arrow-right" />
                    </a>
                    <ul className="feature-list">
                      <li>
                        <i className="far fa-check" /> Create Unique Websites
                      </li>
                      <li>
                        <i className="far fa-check" /> Automate Your Busy Work
                      </li>
                      <li>
                        <i className="far fa-check" /> Optimize All Your Efforts
                      </li>
                      <li>
                        <i className="far fa-check" /> Centralized Teams
                      </li>
                      <li>
                        <i className="far fa-check" /> Shareable Team Libraries
                      </li>
                    </ul>
                    <span className="featured-label">Popular</span>
                  </div>
                </div>
                <div className="pricing-slide-item">
                  <div className="pricing-table pricing-table-three">
                    <h4 className="plan-name">Basic Plan</h4>
                    <p>
                      Consectetu adipiscing elit sedie mod tempor incidie labore
                    </p>
                    <div className="plan-price">
                      <span className="currency">$</span>
                      <span className="price">29.57</span>
                    </div>
                    <a href="#" className="template-btn bordered-btn">
                      Choose Package <i className="far fa-long-arrow-right" />
                    </a>
                    <ul className="feature-list">
                      <li>
                        <i className="far fa-check" /> Create Unique Websites
                      </li>
                      <li>
                        <i className="far fa-check" /> Automate Your Busy Work
                      </li>
                      <li>
                        <i className="far fa-check" /> Optimize All Your Efforts
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="pricing-slide-item">
                  <div className="pricing-table pricing-table-three">
                    <h4 className="plan-name">Premium Plan</h4>
                    <p>
                      Consectetu adipiscing elit sedie mod tempor incidie labore
                    </p>
                    <div className="plan-price">
                      <span className="currency">$</span>
                      <span className="price">59.57</span>
                    </div>
                    <a href="#" className="template-btn bordered-btn">
                      Choose Package <i className="far fa-long-arrow-right" />
                    </a>
                    <ul className="feature-list">
                      <li>
                        <i className="far fa-check" /> Create Unique Websites
                      </li>
                      <li>
                        <i className="far fa-check" /> Automate Your Busy Work
                      </li>
                      <li>
                        <i className="far fa-check" /> Optimize All Your Efforts
                      </li>
                    </ul>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/*====== Pricing Section End ======*/}
      {/*====== Latest New Section Start ======*/}
      <section className="latest-news-section section-gap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="section-heading text-center mb-30">
                <span className="tagline-two both-border">
                  Our News &amp; Blog
                </span>
                <h2 className="title">
                  Get Every Single Update Latest News &amp; Tips
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6 col-sm-10">
              <div className="latest-post-box-two mt-30">
                <div className="post-thumb">
                  <img src="/assets/img/latest-post/06.jpg" alt="Image" />
                  <Link href="/blog-details">
                    <a className="box-link">
                      <i className="far fa-long-arrow-right" />
                    </a>
                  </Link>
                </div>
                <div className="post-content">
                  <h4 className="post-title">
                    <a href="#">Guide To Newly Suppored Modern CSS Pseudo</a>
                  </h4>
                  <p>
                    Sit amet consecte adipiscin eiusmod see tempor incididunt
                    labore dolore
                  </p>
                  <ul className="post-meta">
                    <li>
                      <a href="#">
                        <i className="far fa-calendar-alt" /> 25 Feb 2022
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="far fa-comments" /> Comment (5)
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-10">
              <div className="latest-post-box-two mt-30">
                <div className="post-thumb">
                  <img src="/assets/img/latest-post/07.jpg" alt="Image" />
                  <Link href="/blog-details">
                    <a className="box-link">
                      <i className="far fa-long-arrow-right" />
                    </a>
                  </Link>
                </div>
                <div className="post-content">
                  <h4 className="post-title">
                    <a href="#">Guide To Newly Suppored Modern CSS Pseudo</a>
                  </h4>
                  <p>
                    Sit amet consecte adipiscin eiusmod see tempor incididunt
                    labore dolore
                  </p>
                  <ul className="post-meta">
                    <li>
                      <a href="#">
                        <i className="far fa-calendar-alt" /> 25 Feb 2022
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="far fa-comments" /> Comment (5)
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-10">
              <div className="latest-post-box-two mt-30">
                <div className="post-thumb">
                  <img src="/assets/img/latest-post/08.jpg" alt="Image" />
                  <Link href="/blog-details">
                    <a className="box-link">
                      <i className="far fa-long-arrow-right" />
                    </a>
                  </Link>
                </div>
                <div className="post-content">
                  <h4 className="post-title">
                    <a href="#">Guide To Newly Suppored Modern CSS Pseudo</a>
                  </h4>
                  <p>
                    Sit amet consecte adipiscin eiusmod see tempor incididunt
                    labore dolore
                  </p>
                  <ul className="post-meta">
                    <li>
                      <a href="#">
                        <i className="far fa-calendar-alt" /> 25 Feb 2022
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="far fa-comments" /> Comment (5)
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== Latest New Section End ======*/}
      {/*====== CTA Section Start ======*/}
      <section
        className="cta-section-two"
        style={{ backgroundImage: "url(assets/img/cta-bg.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="cta-content">
                <div className="section-heading heading-white mb-30">
                  <span className="tagline-two">Work Together</span>
                  <h2 className="title">Have Any Project On Your Minds?</h2>
                </div>
                <Link to={'/contact-us'} className="template-btn bordered-btn bordered-white">Let's Talk<i className="far fa-long-arrow-right" /></Link>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default Index5Page;
