import React from 'react';
import {Link} from 'react-router-dom';  
import { Blog, Contact, Home, Pages, Services, Works } from "./menu";
export default function Nav() {
  return (
    <nav className="nav-menu d-none d-xl-block">
    <ul className="primary-menu">
      <li>
        <Link to="/">
          
            Home
            <span className="dd-trigger"> 
            </span>
           
        </Link>
         
      </li>
      <li>
        <Link to="/services">
           
            Services
            <span className="dd-trigger"> 
            </span>
         
        </Link>
         
      </li>
      <li>
        <Link to="/portfolio">
          
        Portfolio
            <span className="dd-trigger"> 
            </span>
        
        </Link> 
      </li>
      {/* <li>
        <Link href="/blog-standard">
          <a>
            Blog
            <span className="dd-trigger">
              <i className="fas fa-angle-down" />
            </span>
          </a>
        </Link>
        <ul className="submenu">
          <Blog />
        </ul>
      </li> */}
      {/* <li>
        <a href="#">
          Pages
          <span className="dd-trigger">
            <i className="fas fa-angle-down" />
          </span>
        </a>
        <ul className="submenu">
          <Pages />
        </ul>
      </li> */}
      <li>
         <Link to='/about-us'> 
            About Us
            <span className="dd-trigger"> 
            </span>
          </Link>
      </li>
 
      
    </ul>
  </nav>
  );
}
