import React from 'react';

export default function PricingTable({active}) {
  return (
    <section className={(active) ? "d-none":"pricing-section pricing-section-line section-gap pricing-blur-bg" }>
    <div className="container">
      <div className="section-heading text-center mb-50">
        <h2 className="title">Best Pricing For Business</h2>
        <span className="tagline">Best SEO Optimization Agency</span>
      </div>
      <div className="tab-content" id="pricingTabContent">
        <div
          className="tab-pane fade show active"
          id="monthly"
          role="tabpanel"
        >
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="pricing-table mt-40">
                <h4 className="plan-name">Basic Plan</h4>
                <p>
                  Amet consectetu adipiscing elit sedie eiusmod tempor
                  incidie labore
                </p>
                <div className="plan-price">
                  <span className="currency">$</span>
                  <span className="price">19.57</span>
                </div>
                <a href="#" className="template-btn bordered-btn">
                  Choose Package <i className="far fa-long-arrow-right" />
                </a>
                <p>
                  Quis autem vel eumcur reprehenderit ea voluptate velit
                  esse quam
                </p>
                <ul className="feature-list">
                  <li>
                    <i className="far fa-check" /> 15 Days Money Back
                    Guarantee
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="pricing-table featured-plan mt-40">
                <h4 className="plan-name">Standard Plan</h4>
                <p>
                  Amet consectetu adipiscing elit sedie eiusmod tempor
                  incidie labore
                </p>
                <div className="plan-price">
                  <span className="currency">$</span>
                  <span className="price">39.86</span>
                </div>
                <a href="#" className="template-btn bordered-btn">
                  Choose Package <i className="far fa-long-arrow-right" />
                </a>
                <p>
                  Quis autem vel eumcur reprehenderit ea voluptate velit
                  esse quam
                </p>
                <ul className="feature-list">
                  <li>
                    <i className="far fa-check" /> 15 Days Money Back
                    Guarantee
                  </li>
                  <li className="disabled">
                    <i className="far fa-check" /> 6 Months Support &amp;
                    Update
                  </li>
                </ul>
                <span className="featured-label">Popular</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="pricing-table mt-40">
                <h4 className="plan-name">Premium Plan</h4>
                <p>
                  Amet consectetu adipiscing elit sedie eiusmod tempor
                  incidie labore
                </p>
                <div className="plan-price">
                  <span className="currency">$</span>
                  <span className="price">59.57</span>
                </div>
                <a href="#" className="template-btn bordered-btn">
                  Choose Package <i className="far fa-long-arrow-right" />
                </a>
                <p>
                  Quis autem vel eumcur reprehenderit ea voluptate velit
                  esse quam
                </p>
                <ul className="feature-list">
                  <li>
                    <i className="far fa-check" /> 15 Days Money Back
                    Guarantee
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="yearly" role="tabpanel">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="pricing-table mt-40">
                <h4 className="plan-name">Basic Plan</h4>
                <p>
                  Amet consectetu adipiscing elit sedie eiusmod tempor
                  incidie labore
                </p>
                <div className="plan-price">
                  <span className="currency">$</span>
                  <span className="price">29.57</span>
                </div>
                <a href="#" className="template-btn bordered-btn">
                  Choose Package <i className="far fa-long-arrow-right" />
                </a>
                <p>
                  Quis autem vel eumcur reprehenderit ea voluptate velit
                  esse quam
                </p>
                <ul className="feature-list">
                  <li>
                    <i className="far fa-check" /> 15 Days Money Back
                    Guarantee
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="pricing-table featured-plan mt-40">
                <h4 className="plan-name">Standard Plan</h4>
                <p>
                  Amet consectetu adipiscing elit sedie eiusmod tempor
                  incidie labore
                </p>
                <div className="plan-price">
                  <span className="currency">$</span>
                  <span className="price">49.86</span>
                </div>
                <a href="#" className="template-btn bordered-btn">
                  Choose Package <i className="far fa-long-arrow-right" />
                </a>
                <p>
                  Quis autem vel eumcur reprehenderit ea voluptate velit
                  esse quam
                </p>
                <ul className="feature-list">
                  <li>
                    <i className="far fa-check" /> 15 Days Money Back
                    Guarantee
                  </li>
                  <li className="disabled">
                    <i className="far fa-check" /> 6 Months Support &amp;
                    Update
                  </li>
                </ul>
                <span className="featured-label">Popular</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="pricing-table mt-40">
                <h4 className="plan-name">Premium Plan</h4>
                <p>
                  Amet consectetu adipiscing elit sedie eiusmod tempor
                  incidie labore
                </p>
                <div className="plan-price">
                  <span className="currency">$</span>
                  <span className="price">99.57</span>
                </div>
                <a href="#" className="template-btn bordered-btn">
                  Choose Package <i className="far fa-long-arrow-right" />
                </a>
                <p>
                  Quis autem vel eumcur reprehenderit ea voluptate velit
                  esse quam
                </p>
                <ul className="feature-list">
                  <li>
                    <i className="far fa-check" /> 15 Days Money Back
                    Guarantee
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}
