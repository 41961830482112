import React from 'react';
import { Outlet } from 'react-router-dom';

// import Header from './Header';
import Footer from './Footer';
import Header1 from './Header1'; 
export default function Layout() {

  const path = window.location.pathname;


  return (
    <> 
    
    <Header1/>
        <Outlet />
      <Footer /> 
    </>
      
  );
}
