import {Link} from 'react-router-dom'; 
import React from 'react';

import { ServicesConstant } from "../Global/Constant";
export const ServiceContainer1 = () =>{
  return (
    <section className="case-section bg-color-off-white section-gap overflow-hidden">
        <div className="container">
          <div className="section-heading text-center mb-30">
            <h2 className="title">Services We Offer For Agency</h2>
            <span className="tagline">Best SEO Optimization Agency</span>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio-items-one mt-30">
                <div className="portfolio-thumb">
                  <img src="../assets/img/portfolio/01.jpg" alt="Image" />
                </div>
                <div className="portfolio-content">
                  <div className="categories">
                    <a href="#">Marketing Strategy</a>
                  </div>
                  <h4 className="title">
                    <a href="#">SEO Marketing Ideas</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio-items-one mt-30">
                <div className="portfolio-thumb">
                  <img src="../assets/img/portfolio/02.jpg" alt="Image" />
                </div>
                <div className="portfolio-content">
                  <div className="categories">
                    <a href="#">Business Planning</a>
                  </div>
                  <h4 className="title">
                    <a href="#">Strategy For Business</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio-items-one mt-30">
                <div className="portfolio-thumb">
                  <img src="../assets/img/portfolio/03.jpg" alt="Image" />
                </div>
                <div className="portfolio-content">
                  <div className="categories">
                    <a href="#">Marketing Strategy</a>
                  </div>
                  <h4 className="title">
                    <a href="#">Social Media Marketing</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio-items-one mt-30">
                <div className="portfolio-thumb">
                  <img src="../assets/img/portfolio/04.jpg" alt="Image" />
                </div>
                <div className="portfolio-content">
                  <div className="categories">
                    <a href="#">SEO Optimizations</a>
                  </div>
                  <h4 className="title">
                    <a href="#">Products Engineering</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio-items-one mt-30">
                <div className="portfolio-thumb">
                  <img src="../assets/img/portfolio/24.jpg" alt="Image" />
                </div>
                <div className="portfolio-content">
                  <div className="categories">
                    <a href="#">SEO Optimizations</a>
                  </div>
                  <h4 className="title">
                    <a href="#">Best Market Analysis</a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio-items-one mt-30">
                <div className="portfolio-thumb">
                  <img src="../assets/img/portfolio/25.jpg" alt="Image" />
                </div>
                <div className="portfolio-content">
                  <div className="categories">
                    <a href="#">SEO Optimizations</a>
                  </div>
                  <h4 className="title">
                    <a href="#">Website Application</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

export const ServiceContainer2 = ({title,subtitle}) =>{ 
  return (
    <section
        className="service-section section-gap bg-color-primary-7 bg-cover-center"
        style={{ backgroundImage: "url(assets/img/service-bg.jpg)" }}
      >
        <div className="container">
          <div className="section-heading mb-30 text-center">
            <h2 className="title">{title}</h2>
            <span className="tagline">{subtitle}</span>
          </div>
          <div className="row service-boxes justify-content-center">
            {ServicesConstant.map((item,key) =>{
            
                 return <div key={key} className="col-lg-3 col-md-6 col-sm-8 col-11 wow fadeInUp" data-wow-delay="0.1s" >
                 <div className="fancy-iconic-box mt-30">
                   <div className="icon">
                     <i className={item.iconClass} />
                   </div>
                   <h4 className="title">
                     <Link to={item.link}>{item.title}</Link>
                   </h4>
                   <p className='text-justify'>{item.description}</p>
                 </div>
               </div>
            })} 
          </div>
        </div>
      </section>
  );
}

export const ServiceContainer3 = () =>{
  return (
    <section className="service-section section-gap">
    <div className="container">
      <div className="section-heading heading-white text-center mb-30">
        <h2 className="title">Services We Provide</h2>
        <span className="tagline">Best SEO Optimization Agency</span>
      </div>
      <div className="row justify-content-center">

      {ServicesConstant.map((item,key) =>{
          return   <div className="col-lg-4 col-sm-6 wow fadeInUp">
            <div className="iconic-box icon-left bg-white mt-30">
              <div className="icon">
                <i className={item.iconClass} />
              </div>
              <div className="content">
                <h5 className="title">
                  <Link href={item.link}>
                    <a>{item.title}</a>
                  </Link>
                </h5>
                <p>{item.title}</p>
              </div>
            </div>
          </div>;
          })}
       
        {/* <div className="col-lg-4 col-sm-6 wow fadeInUp">
          <div className="iconic-box icon-left bg-white mt-30">
            <div className="icon">
              <i className="fal fa-desktop-alt" />
            </div>
            <div className="content">
              <h5 className="title">
                <Link href="/service-details">
                  <a>Marketing Strategy</a>
                </Link>
              </h5>
              <p>Amet consectetur adipi scing elit sed eiusmod tempor</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp">
          <div className="iconic-box icon-left bg-white mt-30">
            <div className="icon">
              <i className="fal fa-desktop-alt" />
            </div>
            <div className="content">
              <h5 className="title">
                <Link href="/service-details">
                  <a>IT Consultations</a>
                </Link>
              </h5>
              <p>Amet consectetur adipi scing elit sed eiusmod tempor</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp">
          <div className="iconic-box icon-left bg-white mt-30">
            <div className="icon">
              <i className="fal fa-desktop-alt" />
            </div>
            <div className="content">
              <h5 className="title">
                <Link href="/service-details">
                  <a>Market Analysis</a>
                </Link>
              </h5>
              <p>Amet consectetur adipi scing elit sed eiusmod tempor</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 wow fadeInUp">
          <div className="iconic-box icon-left bg-white mt-30">
            <div className="icon">
              <i className="fal fa-desktop-alt" />
            </div>
            <div className="content">
              <h5 className="title">
                <Link href="/service-details">
                  <a>Cyber Security</a>
                </Link>
              </h5>
              <p>Amet consectetur adipi scing elit sed eiusmod tempor</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </section>
  );
}


 