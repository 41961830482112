 
export const MyTeamConstant =[
     
    {
        name : "Paras Sharma",
        designation : "Founder & CEO",
        image: "01.jpg",
        socailLink:{
            fb:'',
            twitter:"",
            linkedIn:"",
            youTube:""
        }
     },
     {
        name : "Indu Sharma",
        designation : "Managing Director",
        image: "02.jpg",
        socailLink:{
            fb:'',
            twitter:"",
            linkedIn:"",
            youTube:""
        }
     },
     {
        name : "Anuj Sharma",
        designation : "Lead Wordpress Developer",
        image: "03.jpg",
        socailLink:{
            fb:'',
            twitter:"",
            linkedIn:"",
            youTube:""
        }
     },
     {
        name : "Priyanka Sharma",
        designation : "Business Development Manager",
        image: "04.jpg",
        socailLink:{
            fb:'',
            twitter:"",
            linkedIn:"",
            youTube:""
        }
     }
];

export const ServicesConstant =[
     
        {
            title : "Web Development",
            iconClass : "flaticon-analytics",
            link: "web-development",
            description : "The modern business landscape is highly competitive and progressive, which means that having an attractive website does not guarantee online success."
        },
        {
            title : "Php Development",
            iconClass : "flaticon-click",
            link: "php-development",
            description : "Since the introduction of PHP in the 1990s, it has been the most popular and preferred server-side scripting language for building websites and web applications."
        },
        {
            title : "Web Design",
            iconClass : "flaticon-subscription",
            link: "web-design",
            description : "If you are wondering why your online business or corporate website is not getting the results you expected, there may be something wrong with the current web design."
        },
        {
            title : "Ecommerce Stores",
            iconClass : "flaticon-seo",
            link: "ecom-store",
            description : "Online stores have successfully taken over traditional retail. This is mainly due to the ease of purchase, the secure transaction process."
        },
        {
            title : "SEO",
            iconClass : "flaticon-seo-2",
            link: "seo-service",
            description : " SEO is often seen as important for large companies, but it’s just as important for small businesses looking to expand their reach ."
        },
        {
            title : "Mobile Development",
            iconClass : "flaticon-click",
            link: "mobile-development",
            description : "Today we are all very dependent on high-tech cell phones. Cell phones are undoubtedly the most popular way of transferring information."        
        }
    ];

    export const WorkProcessSteps =[
        {
            title : "Planning and Analysis",
            iconClass : "flaticon-support",
            link: "#",
            description : "Lorem Ipusm"
        },
        {
            title : "Design",
            iconClass : "flaticon-support",
            link: "#",
            description : "Lorem Ipusm"
        },
        {
            title : "Implementation",
            iconClass : "flaticon-support",
            link: "#",
            description : "Lorem Ipusm"
        },
        {
            title : "Testing & QA",
            iconClass : "flaticon-support",
            link: "#",
            description : "Lorem Ipusm"
        },
        {
            title : "Deployment",
            iconClass : "flaticon-support",
            link: "#",
            description : "Lorem Ipusm"
        },
        {
            title : "Maintenance",
            iconClass : "flaticon-support",
            link: "#",
            description : "Lorem Ipusm"
        },
    ];

 
