import { Link } from "react-router-dom";

const QuickLink = () => {
  return (
    <div className="widget nav-widget pl-lg-4">
      <h4 className="widget-title">Quick Link</h4>
      <ul>
        <li> <Link to={`/`}>  Home </Link> </li>
        <li> <Link to={`/services`}>  Services  </Link> </li> 
        <li> <Link to={`/portfolio`}>  Portflio  </Link> </li> 
        <li> <Link to={`/about-us`}>  Aboutus  </Link> </li> 
        <li> <Link to={`/contact-us`}>  Lets Talk  </Link> </li> 
      </ul>
    </div>
  );
};
export default QuickLink;
