import { Link } from "react-router-dom";

const Support = () => {
  return (
    <div className="widget nav-widget">
      <h4 className="widget-title">Support</h4>
      <ul>
        <li> <Link to={`#`}>  Privacy & Policy</Link> </li>
        <li> <Link to={`#`}>  Term & Condition  </Link> </li> 
      </ul>
    </div>
  );
};
export default Support;
