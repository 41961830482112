import {React,useState} from 'react';
import {Link} from 'react-router-dom';  
import Nav from './Nav';
import MobileMenu from './MobileMenu';
export default function Header1({dark}) {
  const [mobileToggle, setMobileToggle] = useState(false);
  const [sidebarToggle, setSidebarToggle] = useState(false);
  return (
    <header className="template-header absolute-header sticky-header">
        <div className="container-fluid container-1550">
          <div className="header-inner">
            <div className="header-left">
              <div className="site-logo">
                <Link href={`/`}>
                  <a>
                    <img
                      src={`/assets/img/${dark ? "logo-white" : "twmlogo"}.png`}
                      alt="Qolle"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="header-center">
              <Nav />
            </div>
            <div className="header-right">
              <ul className="header-extra">

              <Link to={`/contact-us`}  className="template-btn d-none d-md-block">
               
                  Let’s Talk
                  <i className="far fa-long-arrow-right" />
                 
                </Link>
              
                 
                {/* <li className="d-none d-xl-block">
                  <a
                    href="#"
                    className="off-canvas-btn"
                    onClick={() => setSidebarToggle(true)}
                  >
                    <span />
                    <span />
                    <span />
                  </a>
                </li> */}
                <li className="d-xl-none">
                  <div
                    className="navbar-toggler"
                    onClick={() => setMobileToggle(true)}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <MobileMenu active={mobileToggle} close={() => setMobileToggle(false)} />
        {/* <Sidebar active={sidebarToggle} close={() => setSidebarToggle(false)} /> */}
    </header>
  );
}
