import React from 'react';
import { MyTeamConstant } from '../Global/Constant';
import { Link } from 'react-router-dom';

export const Team1 = ({active}) =>{
    return (
        <section className={(!active) ? "d-none" : "team-section section-gap-bottom"}>
        <div className="container-fluid container-1600">
          <div className="row team-member-items">


            {MyTeamConstant.map((item,key) =>{
 
                return <div key={key} className="col-xl-3 col-lg-4 col-sm-6">
                 <div className="member-item mt-30">
                   <div className="member-photo">
                     <img src="/assets/img/team-member/01.jpg" alt="Image One" />
                     <ul className="social-icons">
                        {item.socailLink.fb != '' && 
                            <li>
                                <Link to={item.socailLink.fb}>
                                      <i className="fab fa-facebook-f" />
                                </Link> 
                            </li>
                        }
                        {item.socailLink.twitter != '' && 
                        <li>
                             <Link to={item.twitter.fb}>
                            <i className="fab fa-twitter" />
                             </Link>
                        </li>
                       }
                       {item.socailLink.linkedIn != '' && 
                       <li>
                         <Link to={item.socailLink.linkedIn}>
                           <i className="fab fa-linkedin-in" />
                         </Link>
                       </li>
                      }
                      {item.socailLink.youTube != '' && 
                       <li>
                          <Link to={item.socailLink.youTube}>
                           <i className="fab fa-youtube" />
                        </Link>
                       </li>
                     }
                     </ul>
                   </div>
                   <h4 className="name">
                     <a href="#">{item.name}</a>
                   </h4>
                   <span className="title">{item.designation}</span>
                 </div>
               </div>
            })} 
          </div>
        </div>
      </section>
    );
}
