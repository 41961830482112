import React from 'react';

export default function ContactUsForm() {
  return (
    <section className="contact-from-area section-gap">
    <div className="container">
      <div className="contact-from">
        <div className="section-heading text-center mb-60">
          <h2 className="title">Send Us Message</h2>
          <span className="tagline">Best Digital Agency Solutions</span>
        </div>
        <form action="#" onSubmit={(e) => e.preventDefault()}>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="input-field">
                <input type="text" placeholder="Your Full Name" />
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="input-field">
                <input type="text" placeholder="Phone Number" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="input-field">
                <input type="email" placeholder="Email Address" />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="input-field">
                <input type="text" placeholder="Website" />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="input-field">
                <input type="text" placeholder="Subject" />
              </div>
            </div>
            <div className="col-12">
              <div className="input-field">
                <textarea placeholder="Message" defaultValue={""} />
              </div>
            </div>
            <div className="col-12">
              <button className="template-btn w-100">Send Message</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  );
}
