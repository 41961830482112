import { Fragment } from "react";
import PageTitle from "../layouts/PageTitle";
import ContactUsForm from "../components/ContactUsForm";

const ContactPage = () => {
  return (
    <Fragment>
      <PageTitle pageName={"Contact Us"} />
      <section className="contact-info-section section-gap">
        <div className="container">
          <div className="row justify-content-lg-between justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-10">
              <div className="section-heading mb-30">
                <h3 className="title">
                  Ready To Get Perfect Partner for Web Development Journey
                </h3>
                <span className="tagline">Best SEO Optimization Agency</span>
              </div>
              <div className="contact-info-box wow fadeInUp">
                <div className="single-contact-info">
                  <div className="icon">
                    <i className="fal fa-map-marker-alt" />
                  </div>
                  <div className="content">
                    <h4 className="info-title">Address</h4>
                    <p>
                     Village-Kajiyana <br /> Pinjore , Haryana India 134104
                    </p>
                  </div>
                </div>
                <div className="single-contact-info">
                  <div className="icon">
                    <i className="fal fa-envelope-open" />
                  </div>
                  <div className="content">
                    <h4 className="info-title">Email Us</h4>
                    <p>
                      <a href="mailto:info@truewebmakers.com"><p>info@truewebmakers.com</p> </a>
                      <br /> <a href="https:://truewebmakers.com"><p>www.truewebmakers.com</p> </a>
                    </p>
                  </div>
                </div>
                <div className="single-contact-info">
                  <div className="icon">
                    <i className="fal fa-phone" />
                  </div>
                  <div className="content">
                    <h4 className="info-title">Phone</h4>
                    <p>
                    <a href="tel:+918950990009"> +91 (8950) 99 0009  </a>
                      {/* <br /> +12345678 */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-9">
              <ContactUsForm/>
              {/* <div className="contact-img text-lg-right text-center content-mt-md-50">
                <img
                  src="../assets/img/illustrations/about-illustration.png"
                  alt="Image"
                  className="animate-float-bob-y"
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/*====== Contact Info End ======*/}
      {/*====== Contact Maps Start ======*/}
      <section className="contact-map-area overflow-hidden">
        <h2 className="d-none">title</h2>
        <div className="contact-map">

        
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13709.34731409691!2d76.9693128!3d30.7931872!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f8f8955215b71%3A0x79dd77e2d4fff1e7!2sTrueWebMakers!5e0!3m2!1sen!2sin!4v1720777364179!5m2!1sen!2sin"
            loading="lazy"
          />
        </div>
      </section>
      {/*====== Contact Maps End ======*/}
      {/*====== Contact Form Start ======*/}
    
    </Fragment>
  );
};
export default ContactPage;
